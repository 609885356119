

<template>
  <div class="d-flex" style="height: 80vh !important">
    <div
      class="d-flex flex-column justify-content-center algin-items-center text-center"
      style="flex: 1"
    >
      <div class="">
        <img :src="appLogoImage" alt="register" class="mx-auto" />
        <h3 class="text-center">{{ appName }}</h3>
      </div>
      <div>
        <feather-icon
          icon="CheckCircleIcon"
          style="color: #28c76f !important"
          size="34"
          class="my-2 align-middle"
        />
        <h1>Thank You For Your Register !</h1>
        <p>Welcome aboard! Enjoy your experience on {{ appName }}</p>
        <p>Please verify your email to activate your account.</p>
        <b-button
          variant="success"
          class="mt-2 btn-sm-block"
          to="/login"
        >
          Go To Sign In
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { BLink, BButton, BImg } from "bootstrap-vue";

import { $themeConfig } from '@themeConfig'

import { getAccessTokenFromCookie } from "@/auth/utils";

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  computed: {
    ...mapState(["sent"]),
  },
  setup(props) {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
    beforeRouteEnter(to, from, next) {
    const data = getAccessTokenFromCookie();
    if (data) {
      const role = data[2].role;
      if (role === 'admin') {
        next('/admin-dash');
      } else {
        next('dashboard');
      }
    }else {
      next(true);
    }
  }
};
</script>